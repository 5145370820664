<template>
  <TourTooltip
    :steps="steps"
    @next="nextStep($event)"
    @previous="previousStep($event)"
    @skip="$emit('skip')"
  />
</template>

<script>
import TourTooltip from '@/components/TourTooltip'

export default {
  name: 'TourAssessmentExams',

  components: {
    TourTooltip,
  },

  props: {
    application: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      steps: [
        {
          target: '#v-step-1',
          content: `
            <h4 class="v-step__title">Aqui ficam as provas da avaliação</h4>
            <span class="v-step__body">
              Dentro de cada avaliação estão as suas provas. São elas que você deve acessar para responder.
            </span>`,
          params: {
            placement: 'top',
          },
        },
        {
          target: '#v-step-2',
          content: `
            <h4 class="v-step__title">Atenção às datas</h4>
            <span class="v-step__body">
              Este é o período de aplicação. Ele informa quando você pode começar e até quando deve finalizar a prova.
            </span>`,
          params: {
            placement: 'right',
          },
        },
      ],
    }
  },

  methods: {
    nextStep(tour) {
      if (tour.currentStep === tour.steps.length - 1) {
        this.$router.push({
          name: 'assessmentResults',
          params: {
            assessmentId: this.application.assessmentId,
            applicationId: this.application.id,
          },
        })
        tour.stop()
        this.$emit('next')
      } else {
        tour.nextStep()
      }
    },

    previousStep(tour) {
      if (tour.currentStep === 0) {
        this.$router.push({ name: 'home' })
        this.$emit('previous')
      } else {
        tour.previousStep()
      }
    },
  },
}
</script>
