<template>
  <div>
    <v-tour
      :callbacks="callbacks"
      name="onboarding"
      :steps="steps"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
            :next-step="tour.nextStep"
            :previous-step="tour.previousStep"
            :step="currentStep"
            :stop="tour.stop"
          >
            <div
              slot="actions"
              class="v-step__actions"
            >
              <SasButton
                class="v-step__stop"
                size="small"
                theme="float-dark"
                @click="skipTour()"
              >
                {{ closeStepName || 'Sair do tour' }}
              </SasButton>

              <div class="v-step__actions__actions">
                <SasButton
                  v-if="hasPreviousStep"
                  size="small"
                  theme="secondary"
                  @click="$emit('previous', tour)"
                >
                  Anterior
                </SasButton>

                <SasButton
                  v-if="hasNextStep"
                  size="small"
                  @click="$emit('next', tour)"
                >
                  Próximo
                </SasButton>

                <SasButton
                  v-else
                  size="small"
                  @click="$emit('next', tour)"
                >
                  Finalizar
                </SasButton>
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
const TOUR_ACTIVE_CLASS = 'tour-active'
const BODY_TOUR = 'in-tour'

export default {
  name: 'TourTooltip',

  props: {
    steps: {
      type: Array,
      required: true,
    },

    hasPreviousStep: Boolean,

    hasNextStep: Boolean,

    closeStepName: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      callbacks: {
        onStart: this.onStartTour,
        onNextStep: this.onNextStep,
        onPreviousStep: this.onPreviousStep,
        onStop: this.onStop,
      },
    }
  },

  mounted() {
    this.$tours.onboarding.start()
  },
  currentStep() {
    return this.tour
      .steps
      .find((step, index) => this.tour.currentStep === index)
  },
  methods: {
    onStartTour() {
      document.body.classList.add(BODY_TOUR)
      if (this.steps[0].target !== '#v-step-0') {
        const curStepEl = document.querySelector(this.steps[0].target)
        curStepEl.classList.add(TOUR_ACTIVE_CLASS)
      }
    },

    onNextStep(step) {
      const curStepEl = document.querySelector(this.steps[step].target)
      const nextStepEl = document.querySelector(this.steps[step + 1].target)
      curStepEl.classList.remove(TOUR_ACTIVE_CLASS)
      nextStepEl.classList.add(TOUR_ACTIVE_CLASS)
    },

    onPreviousStep(step) {
      const curStepEl = document.querySelector(this.steps[step].target)
      const prevStepEl = document.querySelector(this.steps[step - 1].target)
      curStepEl.classList.remove(TOUR_ACTIVE_CLASS)
      prevStepEl.classList.add(TOUR_ACTIVE_CLASS)
    },

    finishTutorial() {
      this.$tours.onboarding.stop()
      this.$emit('finish')
    },

    skipTour() {
      this.$tours.onboarding.stop()
      this.$emit('skip')
    },

    onStop() {
      document.body.classList.remove(BODY_TOUR)
      if (document.querySelector(`.${TOUR_ACTIVE_CLASS}`)) {
        document
          .querySelector(`.${TOUR_ACTIVE_CLASS}`)
          .classList.remove(TOUR_ACTIVE_CLASS)
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.v-step
  background: $color-ink !important
  box-shadow: 0 1px 6px rgba(0,0,0,0.5) !important
  border-radius: 8px !important
  filter: none !important
  padding: 24px !important
  text-align: left !important
  z-index: 9999 !important
  max-width: 320px !important

  +mq-s
    position: fixed !important
    box-shadow: unset !important
    border-radius: 0px !important
    transform: unset !important
    top: unset !important
    bottom: 0px !important
    will-change: unset !important
    max-width: 100% !important
    margin-right: 0px !important

  +mq_landscape
    position: fixed !important
    box-shadow: unset !important
    border-radius: 0px !important
    transform: unset !important
    top: 0px !important
    bottom: 0px !important
    left: 65% !important
    will-change: unset !important
    max-width: 100% !important

  +mq-xs
    padding: 16px 24px !important

  &__stop
    color: rgba(255,255,255,.5) !important
    margin-left: -4px
    font-weight: 400

  &__title
    margin-bottom: 8px

  &__body
    letter-spacing: .3px
    line-height: 1.4
    font-weight: 300
    color: rgba(255,255,255,.85)

  .btn--float-dark
    &::before
      content: ""
      position: absolute
      border-bottom: 1px dotted white

  .v-step__arrow

    +mq-s
      display: none

    +mq_landscape
      display: none

  &[x-placement^=bottom]
    margin-top: 12px !important

    +mq_landscape
      margin-top: 0px !important

    .v-step__arrow
      border-bottom-color: $color-ink !important

  &[x-placement^=left]

    .v-step__arrow
      border-left-color: $color-ink !important
      border-width: 24px 0 24px 24px
      right: -8px !important

  &__actions
    margin-top: 24px
    +flex-space-between
    margin-bottom: -8px

    +mq-xs
      margin-top: 0

    &__actions
      +flex-space-between

      .btn ~ .btn
        margin-left: 8px

      .btn--secondary

        +mq_landscape
          display: none

body.in-tour
  pointer-events: none

.tour-active
  position: relative
  z-index: 999
  opacity: 1 !important
  pointer-events: none !important
  user-select: none

  &::before
    border-radius: 4px
    content: ""
    position: absolute
    z-index: -1
    background: inherit
    width: calc(100%)
    height: calc(100%)

  &::after
    content: ""
    position: fixed
    z-index: -2
    opacity: 1 !important
    background: rgba(0,0,0,.4)
    blend-mode: multiply
    width: 100vw
    height: 100vh
    top: 0
    left: 0
    right: 0
    bottom: 0

.tour-active, .v-tour
  pointer-events: auto

.tour-active
  .btn:disabled
    opacity: 1 !important

</style>
